<template>
  <div id="container">
    <section id="mypage">
      <div class="wrap">
        <div class="cts_box">
          <h2 class="sub_ttl"><span>複眼経済塾の{{ withdrawalWord() }}</span></h2>
          <p class="lead_txt">
            複眼経済塾のご利用ありがとうございました。<br>
            以下の「{{ withdrawalWord() }}に関するアンケート」にお答えください。アンケートにお答えいただいた後に、
            <template v-if="isUserJoin()">
              「複眼経済塾を{{ withdrawalWord() }}する」
            </template>
            <template v-else>
              「複眼経済塾の入塾をキャンセルする」
            </template>
            ボタンを押すと複眼経済塾からの{{ withdrawalWord() }}が完了します。<br>
          </p>
          <p class="lead_txt">
            <template v-if="isUserJoin()">
              <!-- 入塾者向け-->
              現在お支払いいただいている月謝充当期間の末日で正式に退塾となり、その後はマイページを含むすべてのコンテンツへのアクセスができなくなります。
            </template>
            <template v-else>
              <!-- 入塾待機者向け-->
              {{ withdrawalWord() }}後には、マイページを含むすべてのコンテンツへのアクセスができなくなります。
            </template>
          </p>
        </div>

        <div class="cts_box">
          <h3 class="min_ttl">{{ withdrawalWord() }}に関するアンケートにご協力ください</h3>
          <p class="lead_txt">
            {{ withdrawalWord() }}の理由を教えて下さい。今後の改善に活かしたいと存じます。<br>
            お手数をお掛け致しますが何卒よろしくお願い申し上げます。<br><br>
            {{ withdrawalWord() }}の理由を一つお選びください。
          </p>
          <form action="">
            <div class="input_set dropped_out">
              <div class="radio">
                <input id="radio01" type="radio" v-model="withdrawal_reason" value="withdrawal_reason_1">
                <label for="radio01">塾の講義内容が自分のイメージと合致しなかった</label>
              </div>

              <div class="radio">
                <input id="radio02" type="radio" v-model="withdrawal_reason" value="withdrawal_reason_2">
                <label for="radio02">塾の講義を十分に活かせなかった</label>
              </div>

              <div class="radio">
                <input id="radio03" type="radio" v-model="withdrawal_reason" value="withdrawal_reason_3">
                <label for="radio03">講義を見る時間的な余裕がなかった</label>
              </div>

              <div class="radio">
                <input id="radio04" type="radio" v-model="withdrawal_reason" value="withdrawal_reason_4">
                <label for="radio04">資金的な理由で継続が難しくなった</label>
              </div>

              <div class="radio">
                <input id="radio05" type="radio" v-model="withdrawal_reason" value="withdrawal_reason_5">
                <label for="radio05">他の投資スクールに入ることになった</label>
              </div>

              <div class="radio">
                <input id="radio06" type="radio" v-model="withdrawal_reason" value="withdrawal_reason_6">
                <label for="radio06">その他</label>
              </div>
              <div class="textarea">
                <textarea v-if="isUserJoin()" v-model="withdrawal_reason_text"
                          placeholder="退塾理由について（自由にご記入ください）"></textarea>
                <textarea v-else v-model="withdrawal_reason_text"
                          placeholder="入塾キャンセル理由について（自由にご記入ください）"></textarea>
              </div>
              <p class="error_msg" v-if="error">必ず一つは選択してください。</p>
            </div>

            <div class="button_wrap">
              <p class="alnC sp_alnL mb10">アンケートに入力後にボタンを押してください。</p>

              <input type="button" class="submit arrow large light arrow" value="複眼経済塾を退塾する"
                     v-if="isUserJoin()" @click="membershipWithdrawal">
              <input type="button" class="submit arrow large light arrow" value="複眼経済塾の入塾をキャンセルする"
                     v-else @click="membershipWithdrawal">
            </div>
            <div class="button_wrap">
              <p class="alnC sp_alnL mb10">{{
                  withdrawalWord()
                }}を希望されない方はこちらのボタンからマイページにお戻りください。</p>
              <router-link :to="{ name: 'UsersCustomerAccount'}" class="submit arrow large light skeleton arrow back">
                マイページに戻る
              </router-link>
            </div>
          </form>
        </div>

      </div>
    </section>

  </div>
</template>

<script>

import Libraries from '@/components/Libraries'

export default {
  mixins: [Libraries],
  data() {
    return {
      withdrawal_reason: null,
      withdrawal_reason_text: null,
      error: false
    }
  },
  methods: {
    membershipWithdrawal() {
      if (!this.withdrawal_reason) {
        this.error = true
        return
      }
      this.error = false
      if (!confirm(`${this.withdrawalWord()}の申請を実施しますか？`)) {
        return
      }
      this.axios
          .post(`${this.env.api_base_url}users/membership_withdrawal.json`, {
            user: {
              withdrawal_reason: this.withdrawal_reason,
              withdrawal_reason_text: this.withdrawal_reason_text
            },
          })
          .then(response => {
            // console.log(response.data)
            if (this.isUserJoin()) {
              if (response.data.withdrawal_at) {
                const message = `退塾の申請を受け付けました。\n実際に退塾へと移行するのは、${this.moment(response.data.withdrawal_at, 'YYYY年MM月DD日')} となります。\nマイページへ戻ります。`
                alert(message)
              } else {
                const message = `退塾の申請を受理出来ませんでした。\n事務局までお問い合わせください。\nマイページへ戻ります。`
                alert(message)
              }
              // マイページへ
              this.$router.push({name: 'UsersCustomerAccount'})
            } else {
              // TOPへ
              alert('入塾キャンセル手続きが完了いたしました。\n機会がございましたら、またのご利用をお待ちしております。\nTOPページへ戻ります。')
              this.removeSession();
              location.href = '/'
            }
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
          })
    },
    withdrawalWord() {
      return this.isUserJoin() ? '退塾' : '入塾キャンセル'

    }
  }
}
</script>
